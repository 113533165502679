import Vue from "vue";
import router from "../router";
import axios from "../commons/AxiosMethod.js";
import { ElMessageBox, ElMessage } from "element-plus";

const tools = {
  InputSelect(e) {
    console.log(e);
    e.currentTarget.select();
  },
  // 确认删除确认框
  confirmDelete(itemName, then, catchs) {
    ElMessageBox.confirm(
      "确定要删除<span class='optName'>" + itemName + "</span>吗?",
      "提示",
      { dangerouslyUseHTMLString: true }
    )
      .then(then)
      .catch(() => {
        ElMessage({
          message: "取消删除",
          type: "warning",
        });
      });
  }, // 确认框
  confirm(tips, then, catchs) {
    ElMessageBox.confirm(tips, "提示", { dangerouslyUseHTMLString: true })
      .then(then)
      .catch(() => {
        ElMessage({
          message: "已取消操作",
          type: "warning",
        });
      });
  },
  GoPage(url) {
    router.push({
      path: url,
      query: {
        s: Date.now(),
      },
    });
  },
  to(url) {
    router.push({
      name: url,
    });
  },
  toParams(url, parma) {
    router.push({
      name: url,
      query: parma,
    });
  },
  ToPage(url) {
    router.push({
      name: "ProductDetail",
      query: { Id: this.Product.Id },
    });
  },
  S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  },
  guid() {
    return (
      this.S4() +
      this.S4() +
      "-" +
      this.S4() +
      "-" +
      this.S4() +
      "-" +
      this.S4() +
      "-" +
      this.S4() +
      this.S4() +
      this.S4()
    );
  }, //获取语言值
  GetLanguageValue(key) {
    try {
      var languageMap = JSON.parse(localStorage.getItem("LanguageRepertory"));

      var val = languageMap[key];
      if (!!!val) {
        val = key;
      }

      return val;
    } catch (e) {}
  }, // 加载基本数据
  GetLanguageById(languageId, refresh) {
    axios.apiMethod(
      "/api/Language/GetLanguageById",
      "get",
      {
        languageId: languageId,
      },
      (result) => {
        localStorage.setItem("LanguageRepertory", JSON.stringify(result.Data));
        console.log("存入指定语言数据"+  JSON.stringify(result.Data ))
        try {
          refresh();
        } catch (element) {}
      }
    );
  },
  GetLanguageDefault(refresh) {
    axios.apiMethod("/api/Language/GetLanguageDefault", "get", {}, (result) => {
      localStorage.setItem(
        "LanguageRepertory",
        JSON.stringify(result.Data.keyValuePairs)
      );
      console.log("存入默认语言数据"+  JSON.stringify(result.Data.keyValuePairs))
      localStorage.setItem("LanguageId", result.Data.LanguageId);
      try {
        refresh();
      } catch (element) {}
    });
  },

  //根据语言Id加载语言的所有值,并且缓存
  GetLanguageByIdToCache(languageId) {
    axios.apiMethod(
      "/api/Language/GetLanguageById",
      "get",
      {
        languageId: languageId,
      },
      (result) => {
        var json = JSON.stringify(result.Data);
        localStorage.setItem(languageId + "LanguageRepertory", json);
      }
    );
  },
  //获取语言值
  GetPrintLanguageValue(key) {
    try {
      var printLanguageId = localStorage.getItem("PrintLanguageId");
      var languageMap = JSON.parse(
        localStorage.getItem(printLanguageId + "LanguageRepertory")
      );

      var val = languageMap[key];
      if (!!!val) {
        val = key;
      }

      return val;
    } catch (e) {}
  },
};

export default tools;
