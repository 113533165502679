import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import es from 'element-plus/es/locale/lang/es'
import en from 'element-plus/es/locale/lang/en'
import fr from 'element-plus/es/locale/lang/fr'
import it from 'element-plus/es/locale/lang/it'

import axios from './commons/AxiosMethod.js'
import commons from './commons/commons.js'
import tools from './commons/tools.js'

// import "./assets/css/bootstrap.min.css"
import "./assets/css/commons.css"
import "./assets/patternLocks/patternlock.css"
import "./assets/patternLocks/index.css"


import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

var locale = zhCn;

var code = localStorage.getItem("LanguageCode") + "";

const messages = { en: en, zhCn, es, fr }

createApp(App).use(store).use(router).use(axios).use(tools).use(commons).use(ElementPlus, {
  locale: code == 'Español' ? es : (code == 'zh-cn' ? zhCn : (code == 'English' ? en : ((code == 'ITA' ? fr : zhCn))))

}).mount("#app")

