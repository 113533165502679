import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "@/Login.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
  },
  //商家登录
  {
    path: "/storeLogin",
    name: "storeLogin",
    component: () => import("../views/Stores/Accounts/Login.vue"),
  },
  {
    path: "/adminLogin",
    name: "adminLogin",
    component: Home
  },

  {
    path: "/accountManage",
    name: "accountManage",
    component: () =>
      import("../views/Admins/Stores/AccountManage.vue"),
  },

  {
    path: "/manages",
    name: "manages",
    component: () =>
      import("../views/Admins/Sys/Manages.vue"),
  },
  {
    path: "/manufactureConsole",
    name: "manufactureConsole",
    component: () =>
      import("../views/Admins/Consoles/ManufactureConsole.vue"),
  },

  {
    path: "/mapdata",
    name: "mapdata",
    component: () =>
      import("../views/Admins/Sys/MapData.vue"),
  },
  {
    path: "/directiondoruse",
    name: "directiondoruse",
    component: () =>
      import("../views/Admins/Sys/DirectionForUse.vue"),
  },
  {
    path: "/account",
    name: "account",
    component: () =>
      import("../views/Admins/Sys/Accounts.vue"),
  }, {
    path: "/role",
    name: "role",
    component: () =>
      import("../views/Admins/Sys/Role.vue"),
  }, {
    path: "/classify",
    name: "classify",
    component: () =>
      import("../views/Admins/Products/classify.vue"),
  }, {
    path: "/OrderManage",//订单管理
    name: "OrderManage",
    component: () =>
      import("../views/Admins/Sys/OrderManage.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () =>
      import("../views/Admins/Products/product.vue"),
  },
  {
    path: "/purchaseMoney",
    name: "purchaseMoney",
    component: () =>
      import("../views/Admins/Sys/PurchaseMoney.vue"),
  }, {
    path: "/AdminHome",
    name: "AdminHome",
    component: () =>
      import("../views/Admins/Sys/AdminHome.vue"),
  }, {
    path: "/productCollection",
    name: "productCollection",
    component: () =>
      import("../views/Admins/Products/productCollection.vue"),
  }, {
    path: "/productDetail", //商品详情页面
    name: "productDetail",
    component: () =>
      import("../views/Stores/Data/Products/ProductDetail.vue"),

  }, {
    path: "/attachment", //配件管理
    name: "attachment",
    component: () =>
      import("../views/Stores/Data/Products/Attachment.vue"),
  }, {
    path: "/storagesManage", //仓储管理
    name: "storagesManage",
    component: () =>
      import("../views/Stores/Data/Storages/StoragesManage.vue"),
  },
  {
    path: "/Category", //分类商品页面
    name: "category",
    component: () =>
      import("../views/Stores/Data/Category.vue"),
  }, {
    path: "/productList", //分类商品页面
    name: "productList",
    component: () =>
      import("../views/Stores/Data/Products/ProductList.vue"),
  },
  {
    path: "/productBrand", //品牌
    name: "productBrand",
    component: () =>
      import("../views/Stores/Data/Products/ProductBrand.vue"),
  },
  {
    path: "/productModel", //型号
    name: "productModel",
    component: () =>
      import("../views/Stores/Data/Products/ProductModel.vue"),
  },
  //供应商
  {
    path: "/storeSupplier",
    name: "storeSupplier",
    component: () =>
      import("../views/Stores/Data/Products/StoreSupplier.vue"),
  },
  {
    path: "/productServer", // 服务类型
    name: "productServer",
    component: () =>
      import("../views/Stores/Data/Products/ProductServer.vue"),
  },
  {
    path: "/marketList", // 销售单列表
    name: "marketList",
    component: () =>
      import("../views/Stores/Markets/MarketList.vue"),
  }, {
    path: "/marketDetail", // 销售单列表
    name: "marketDetail",
    component: () =>
      import("../views/Stores/Markets/MarketDetail.vue"),
  },
  {
    path: "/marketQuick", // 快捷销售
    name: "marketQuick",
    component: () =>
      import("../views/Stores/Markets/MarketQuick.vue"),
    meta: {
      keepAlive: true // 启用缓存
    }
  },
  {
    path: "/marketReceipt", // 销售小票
    name: "marketReceipt",
    component: () =>
      import("../views/Stores/Markets/MarketReceipt.vue"),
    meta: {
      keepAlive: true // 启用缓存
    }
  },
  {
    path: "/attribute", //分类商品页面
    name: "attribute",
    component: () =>
      import("../views/Stores/Data/Attribute.vue"),
  },
  {
    path: "/Configurations", //系统配置
    name: "Configurations",
    component: () =>
      import("../views/Admins/Sys/Configurations.vue"),
  },
  {
    path: "/HomeAdvertPicture",
    name: "HomeAdvertPicture",
    component: () =>
      import("../views/Admins/Adverts/HomeAdvertPicture.vue"),
  },

  //管理通知
  {
    path: "/manageNotices",
    name: "manageNotices",
    component: () =>
      import("../views/Admins/Adverts/ManageNotices.vue"),
  },

  {
    path: "/languageManages",
    name: "languageManages",
    component: () =>
      import("../views/Admins/Sys/LanguageManages.vue"),
  }

  , {
    path: "/sysCompanysManage",
    name: "sysCompanysManage",
    component: () =>
      import("../views/Admins/Stores/CompanysManage.vue"),

  }, {
    path: "/sysStoreManage",
    name: "sysStoreManage",
    component: () =>
      import("../views/Admins/Stores/StoreManage.vue"),

  },
  //商户默认数据
  {
    path: "/storeDefaultDataManage",
    name: "storeDefaultDataManage",
    component: () =>
      import("../views/Admins/Stores/StoreDefaultDataManage.vue"),
  }, 
  {
    path: "/renew",
    name: "renew",
    component: () =>
      import("../views/Stores/Sys/Renew.vue"), 
  },
  {
    path: "/renewApplyFor",
    name: "renewApplyFor",
    component: () =>
      import("../views/Stores/Sys/RenewApplyFor.vue"), 
  },
 
  {
    path: "/sysRepairManage",
    name: "sysRepairManage",
    component: () =>
      import("../views/Stores/Sys/SysRepairManage.vue"),

  },
  {
    path: "/storeRegister",
    name: "storeRegister",
    component: () =>
      import("../views/Stores/Accounts/StoreRegister.vue"),

  },



  //------------------------------------------商户--------------------------------------------


  // {
  //   path: "/storeUserManage",
  //   name: "storeUserManage",
  //   component: () =>
  //     import("../views/Stores/Accounts/StoreUserManage.vue"),
  // },

  {
    path: "/storeLogin",
    name: "storeLogin",
    component: () =>
      import("../views/Stores/Accounts/Login.vue"),
  },
  {
    path: "/storeHome",
    name: "storeHome",
    component: () =>
      import("../views/Stores/Home.vue"),
  }, {
    path: "/userManage",
    name: "userManage",
    component: () =>
      import("../views/Stores/Accounts/UserManage.vue"),
  }, {
    path: "/storeManage",
    name: "storeManage",
    component: () =>
      import("../views/Stores/Accounts/StoreManage.vue"),
  }, {
    path: "/navigation",
    name: "navigation",
    component: () =>
      import("../components/Modules/Navigation.vue"),
  }
  , {
    path: "/clients",
    name: "clients",
    component: () =>
      import("../views/Stores/Clients/ClientManage.vue"),
  }, {
    path: "/repairs",
    name: "repairs",
    component: () =>
      import("../views/Stores/Repairs/RepairManage.vue"),
  }, 
  {
    path: "/repairClient",
    name: "repairClient",
    component: () =>
      import("../views/Stores/Repairs/RepairClient.vue"),
  }, 
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () =>
      import("../views/Stores/AboutUs.vue"),
  }, 


  {
    path: "/repairDetail",
    name: "repairDetail",

    component: () =>
      import("../views/Stores/Repairs/RepairDetail.vue"),

    props: true
  },
  {
    path: "/adminRepairDetail",
    name: "adminRepairDetail",
    component: () =>
      import("../views/Stores/Sys/AdminRepairDetail.vue"),
    props: true
  },


  {
    path: "/settings",
    name: "settings",
    component: () =>
      import("../views/Stores/Settings/SettingsManage.vue"),
    props: true
  },

  {
    path: "/printRepairReserve",
    name: "printRepairReserve",
    component: () =>
      import("../views/Stores/Repairs/PrintRepairReserve.vue"),
    props: true
  },

  {
    path: "/printRepairReserveA4",
    name: "printRepairReserveA4",
    component: () =>
      import("../views/Stores/Repairs/PrintRepairReserveA4.vue"),
    props: true
  },

  {
    path: "/printXiaoPiaoEighty",
    name: "printXiaoPiaoEighty",
    component: () =>
      import("../views/Stores/Repairs/printXiaoPiaoEighty.vue"),
    props: true
  }, {
    path: "/printWarranty",
    name: "printWarranty",
    component: () =>
      import("../views/Stores/Repairs/PrintWarranty.vue"),
    props: true
  }, {
    path: "/printXiaoPiaoAfour",
    name: "printXiaoPiaoAfour",
    component: () =>
      import("../views/Stores/Repairs/PrintReceiptAfour.vue"),
    props: true
  },


  // --------------------------------------公司-------------------------------------

  {
    path: "/companyHome",
    name: "companyHome",
    component: () =>
      import("../views/Companys/CompanyHome.vue"),
    props: true
  },
  {
    path: "/companyUserManage",
    name: "companyUserManage",
    component: () =>
      import("../views/Admins/Stores/CompanyUserManage.vue"),
    props: true
  },
  {
    path: "/companyRepairsQuantityReport",
    name: "companyRepairsQuantityReport",
    component: () =>
      import("../views/Companys/CompanyRepairsQuantityReport.vue"),
    props: true
  }
  ,
  {
    path: "/applyFor",
    name: "applyFor",
    component: () =>
      import("../views/Admins/Stores/ApplyFor.vue"),
    props: true
  }



  // --------------------------------------报表-------------------------------------
  , {
    path: "/reportMaintainPay",
    name: "reportMaintainPay",
    component: () =>
      import("../views/Stores/Reports/ReportMaintainPay.vue"),
  }
  , {
    path: "/reportMaintain",
    name: "reportMaintain",
    component: () =>
      import("../views/Stores/Reports/ReportMaintain.vue"),
  }, {
    path: "/companyLogin",
    name: "companyLogin",
    component: () =>
      import("../views/Companys/CompanyLogin.vue"),
  }, {
    path: "/compantCollectReport",
    name: "compantCollectReport",
    component: () =>
      import("../views/Companys/CompantCollectReport.vue"),
  },
  //销售报表
  {
    path: "/reportMarket",
    name: "reportMarket",
    component: () =>
      import("../views/Stores/Reports/ReportMarket.vue"),
  },
  //维修进度
  {
    path: "/repairSchedule",
    name: "repairSchedule",
    component: () =>
      import("../views/Stores/Repairs/RepairSchedule.vue"),
  }




];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
