// packages / index.js

// 导入单个组件
import Vue from "vue"
import router from "../router"

import { ElMessageBox, ElMessage } from "element-plus"

const commons = {
  // 纯属调取接口
  topage (url) {

  },
  // 确认删除确认框
  confirmDelete (itemName, then, catchs) {
    ElMessageBox.confirm("确定要删除<span class='optName'>" + itemName + "</span>吗?", '提示', { dangerouslyUseHTMLString: true })
      .then(then)
      .catch(() => {
        ElMessage({
          message: "取消删除",
          type: 'warning'
        })
      })
  }
}
export default commons

