<template>
  <div>
    <div class="login-container" :style="{ height: height + 'px' }">
      <el-form
        :model="ruleForm2"
        :rules="rules2"
        status-icon
        ref="ruleForm2"
        label-position="left"
        label-width="0px"
        class="demo-ruleForm login-page"
      >
        <div  style="margin: 15px;font-size: 20pt;">威视科技-VisionTec</div>
        <el-form-item prop="username">
          <el-input
            type="text"
            v-model="AccountModel.Account"
            auto-complete="off"
            placeholder="用户名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-on:keyup.enter="login"
            v-model="AccountModel.Password"
            auto-complete="off"
            placeholder="密码"
          ></el-input>
        </el-form-item>

        <el-form-item style="width: 100%">
          <el-button
            type="primary"
            style="width: 100%; height: 40px"
            @click="login"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>

  <div class="content"></div>
</template>
<script>
import axios from "../src/commons/AxiosMethod.js";
import { ElMessageBox } from "element-plus";
export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data() {
    return {
      AccountModel: {
        Account: "",
        Password: "",
        Checked: false,
        Code: "",
        AccountRole: [],
        apiUrl: "",
      },
      height: 500,
    };
  },
  name: "App",

  components: {},
  mounted() {
    this.apiUrl = process.env.VUE_APP_BASE_API;

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.height = document.documentElement.clientHeight - 10;
      })();
    };
    this.height = document.documentElement.clientHeight - 10;
  },
  methods: {
    VerificationLogin() {
      var bearer = localStorage.getItem("Authentication_Header");
      if (bearer != "" || bearer != null || bearer.length > 15) {
        axios.apiMethod(
          "/Home/VerificationLogin",
          "get",
          {},
          (result) => {
            if (result.Data == "store") {
              this.$router.push({
                path: "storeLogin",
              });
              return;
            }
            this.$router.push({
              path: "manages",
            });
          },
          (d) => {
            alert("ss");
          }
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    login() {
      axios.apiMethod(
        "/Home/Login",
        "post",
        this.AccountModel,

        (s) => {
          if (s.Success) {
            localStorage.setItem("Authentication_Header", "Bearer " + s.Token);
            localStorage.setItem("User_Name", s.Name);
            localStorage.setItem("User_Image", s.Image);
            localStorage.setItem("UserInfo", JSON.stringify(s.UserInfo));

            this.$router.push({
              path: "AdminHome",
            });
          } else {
            ElMessageBox({
              title: "提示",
              message: s.Msg,
              type: "error",
            });
          }
        },
        (e) => {}
      );
    },
    test() {
      axios.apiMethod(
        "/Admins/Account/UserInfo",
        "get",
        this.AccountModel,
        (s) => {
          console.log(JSON.stringify(s));
          localStorage.setItem("Authentication_Header", s.token);
        }
      );
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100%;
  height: 100%;
  width: 100%;
  background-image: url("../src/assets/images/adminloginback.jpg");
  background-size: cover;
  background-position: 50% 50%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
 
}

.login-page {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 150px auto;
  width: 350px;
  padding:  5px 35px 35px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

label.el-checkbox.rememberme {
  margin: 0px 0px 15px;
  text-align: left;
}
</style>
