<template>
  <router-view v-if="!$route.meta.keepAlive" class="app_conetnt" />

  <keep-alive v-if="$route.meta.keepAlive">
    <router-view class="app_conetnt" />
  </keep-alive>
</template>

<script >
import axios from "../src/commons/AxiosMethod.js";
import tools from "../src/commons/tools.js";
export default {
  data() {
    return {
      tools: tools,
      LanguageId: null,
    };
  },
  name: "App",
  mounted() {
    var paths = ["/repairSchedule", "/storeRegister","/renewApplyFor","/adminlogin"];

    if (!(paths.indexOf(window.location.pathname) > -1)) {
 
      this.VerificationStoreLogin();
    }
  },
  components: {},
  methods: {
    // 验证taken是否有效
    VerificationStoreLogin() {
      axios.apiMethod("/Home/VerificationStoreLogin", "GET", {}, (result) => {
        if (!result.Data.IsSuccess) {
          this.$router.push({
            path: "/",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
#app {
  // font-family: "Consolas";
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // border-radius: 7px;
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // font-family: "Microsoft JhengHei";
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
  // margin: 0 auto;
}
.main-wrapper {
  margin: 20px;
  width: 100%;
}

.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style>

.fonthidden { 
  overflow: hidden; 
        text-overflow: ellipsis;  
        display: -webkit-box; 
        -webkit-line-clamp: 2;  
        -webkit-box-orient: vertical;  

 
}

.el-table__header tr th {
  font-weight: none !important;
}

.el-table__header {
  font-family: "Microsoft JhengHei"; /* 修改为你想要的字体 */
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}

.topnavigation {
  height: 60px;
  background-color: #545c64;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 10px;
}

#ex-direction .patt-circ .dir {
  background-image: url("../src/assets/images/icon-arrow.png");
  background-position: center;
  background-repeat: no-repeat;
}
.w-e-menu {
  z-index: 1 !important;
}
.w-e-toolbar {
  z-index: 2 !important;
}
.w-e-text-container {
  z-index: 1 !important;
}

.eloption .el-select-dropdown__wrap {
  max-height: 450px !important;
}
.el-menu {
  border-right: solid 0px var(--el-menu-border-color) !important;
}
</style>
